html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
img,
b,
ol,
ul,
li,
fieldset,
form,
label,
legend,
footer,
header,
nav,
section {
  border: 0;
  box-sizing: border-box;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
footer,
header,
nav,
section {
  display: block;
}
html {
  scroll-behavior: smooth;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
b,
strong {
  font-weight: bold;
}
